const initDropdowns = () => {
  document.querySelectorAll('.dropdown-button').forEach((toggle) => {
    toggle.addEventListener('click', (event) => {
      event.preventDefault();
      toggle.closest('.dropdown-group')?.querySelector('.dropdown-menu')?.classList.toggle('hidden');
    });
  });
};

export default initDropdowns;
