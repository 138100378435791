const initTooltips = () => {
  document.querySelectorAll('.tooltip-trigger').forEach((trigger) => {
    trigger.addEventListener('mouseover', () => {
      trigger.closest('.tooltip-group')?.querySelector('.tooltip-body')?.classList.remove('hidden');
    });

    trigger.addEventListener('mouseout', () => {
      trigger.closest('.tooltip-group')?.querySelector('.tooltip-body')?.classList.add('hidden');
    });
  });
};

export default initTooltips;
