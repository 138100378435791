/* eslint-disable func-names */

import { initSelect2 } from 'src/typeaheads/typeaheads';
import moment from 'moment';

type ChallengeCalendarDocument = Document & {
  $calendar?: any;
  challengeId?: any;
  userId?: any;
  completedDates?: any;
  recordedDates?: any;
  applyCheckMarks?: any;
  calendar?: any;
};

type ChallengeCalendarWindow = Window & {
  App?: any;
};

$(function () {
  const self: ChallengeCalendarDocument = this;

  self.$calendar = $('#challenge-calendar');

  if (self.$calendar.length === 0) {
    return;
  }

  self.challengeId = self.$calendar.data('id');
  self.userId = self.$calendar.data('user');
  self.completedDates = self.$calendar.data('completed').split(',');
  self.recordedDates = self.$calendar.data('recorded').split(',');

  self.applyCheckMarks = function () {
    self.recordedDates.forEach((date) => {
      if (date) {
        const $calendarDay = $(`.calendar-day-${date}`);
        $calendarDay.addClass('recorded');
        if (self.completedDates.indexOf(date) !== -1) {
          $calendarDay.addClass('event');
        }
      }
    });
  };

  self.calendar = self.$calendar.clndr({
    constraints: {
      startDate: self.$calendar.data('start'),
      endDate: self.$calendar.data('end'),
    },
    template: $('#calendar_template').html(),
    events: [],
    showNav: {},
    showAdjacentMonths: false,
    clickEvents: {
      click(target) {
        if (!$(target.element).hasClass('inactive') && !$(target.element).hasClass('empty')) {
          if (target.date > moment()) return;
          // eslint-disable-next-line no-underscore-dangle
          let path = `/challenges/${self.challengeId}/todos?date=${target.date._i}`;

          // @ts-ignore
          const inst = $('[data-remodal-id=todo-remodal]').remodal();
          inst.open();

          if (self.userId) {
            path += `&user_id=${self.userId}`;
          }

          $('.todos .content').load(path, function () {
            $('.todo-list #remodal-back-btn').removeClass('hidden');
            if (target.date > moment()) {
              $('.todos .content a.todo-item-single').addClass('btn-disabled');
            }

            $('.todos .content form').each((i, e) => {
              $(e).append($('<input type="hidden" name="calendar" value="true" />'));
            });

            $(this).parents('.todos').addClass('active');
            // eslint-disable-next-line no-underscore-dangle
            $(this).parents('.todos').find('#todo-date-today').html(moment(target.date._i).format('dddd, MMMM D'));

            $('.todo-item', $(this)).parents('a').each((i, e) => {
              const identifier = `[data-remodal-id=${$(e).attr('data-remodal-target')}]`;
              // @ts-ignore
              $(identifier).remodal();
            });

            $('.todo-item-single.btn-disabled').each(function () {
              $(this).find('.todo-item-text').append('<span class="text-danger">Recording disabled</span>');
            });

            initSelect2();

            (window as ChallengeCalendarWindow).App.stats.initAll();
            (window as ChallengeCalendarWindow).App.timeline.initAll();
          });
        }

        $('.todos .close-btn').on('click', () => {
          $('.todos').removeClass('active');
        });
      },

      nextMonth: self.applyCheckMarks,
      previousMonth: self.applyCheckMarks,
    },
    ready: self.applyCheckMarks,
  });
});
