import PageLoader from 'src/page-loader';
import InitFaviconSwapper from 'src/favicon-swapper';
import { setupInputFocus } from 'src/typeaheads/select2-configs';
import SetupUserContent from 'src/user-content';
import sessionTimeout from 'src/sessionTimeout';
import { initSelect2 } from 'src/typeaheads/typeaheads';
import initDropdowns from './dropdowns';
import initTooltips from './tooltips';
import { initPortalCalendars } from '../../common/calendars';
import 'ui/components/Challenges/challenge-calendar';
import '../leaderboard';
import '../../common/axios';

InitFaviconSwapper();
document.addEventListener('DOMContentLoaded', () => {
  initSelect2();
  window.App.initSelect2 = initSelect2;

  setupInputFocus();
  initDropdowns();
  initTooltips();
  initPortalCalendars();
  PageLoader();
  SetupUserContent();
  sessionTimeout();

  $('.carat-toggle').on('click', function (e) {
    $(this).find('span > svg').toggleClass('rotate-180');
    $(`.${e.currentTarget.dataset.target}`).toggleClass('hidden');
  });
});
